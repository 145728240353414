/* You can add global styles to this file, and also import other style files */
@import "~font-awesome/css/font-awesome.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
:root {
  --body-bg: #e3e8f4;
  --white-bg: #ffffff;
  --white-text: #ffffff;
  --black-bg: #000000;
  --black-text: #000000;
  --grey-color: #cccccc;
  --a-hover: #1b4b91;
  --header-bg: #195991;
  --bg-hover: #e0f3ff;
  --light-blue-bg: #e0f3ff;
  --blue-bg:#2370b0;
  --sidebar-bg: #fff;
  --blue-text:#1b4b91;
  --main-heading-bg:#1b4b91;
  --login-inner-bg: #43444a;
  --grey-border: #a3a1a7;
  --dark-bg: #2b2f35;
  --logo-light-color: #fb4b72;
  --light-header-bg: #fafbfc;
  --sidebar-color-text:#000;
  --back-rate: #72bbef;
  --lay-rate: #faa9ba;
  --back-light: #e9f6fc;
  --lay-light: #fce3e4;
  --light-border: #e2ecf5;
  --positive:#00b82b;
  --positive-dark: #176d1a;
  --error: #ef0303;
  --error-dark: #a21c12;
}

html, body {
  height: 100%;
}

html body, .mat-body, .mat-body-1, body.mat-typography {
  font-family: "Poppins", sans-serif;
}

body {
  position: relative;
  margin: 0 auto;
  padding: 0;
  background-color: var(--body-bg);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--black-text);
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
}

a, a:hover {
  text-decoration: none;
}

a:hover {
  color: var(--a-hover);
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.row > * {
  padding-right: 0;
  padding-left: 0;
}

.pr-10 {
  padding-right: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mt-5 {
  margin-top: 10px !important;
}

.mb-5 {
  margin-bottom: 10px !important;
}

.p-0 {
  padding: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #aac2d5 !important;
  font-weight: 400;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aac2d5 !important;
  font-weight: 400;
}

::placeholder {
  color: #aac2d5 !important;
  font-weight: 400;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #aac2d5 !important;
  font-weight: 400;
  font-size: 12px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-weight: 500;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.inplay {
  font-size: 10px;
  color: var(--positive);
}

.form-list {
  margin-bottom: 15px;
}

.form-heading {
  margin-bottom: 0.5rem;
}

.form-attribute {
  display: block;
  width: 100%;
  padding: 0 10px;
}

.form-select, .form-select:focus, .form-control, .form-control:focus,
.form-attribute, .form-attribute:focus {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 34px;
  color: #495057;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  outline: none;
}

.form-label, .form-heading {
  font-weight: 600;
  color: #495057;
  font-size: 13px;
}

.form-check {
  display: inline-block;
  margin-right: 15px;
  padding-left: 0;
}
.form-check input {
  padding: 0;
  height: auto;
  width: auto;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.form-check input:checked + label:before {
  background-color: var(--blue-bg);
}
.form-check label {
  position: relative;
  cursor: pointer;
  font-size: 13px;
  line-height: 18px;
}
.form-check label:before {
  content: "";
  -webkit-appearance: none;
  background-color: initial;
  border: 2px solid var(--blue-bg);
  box-shadow: 0 1px 2px rgba(241, 174, 174, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  float: left;
  border-radius: 2px;
}
.form-check label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form-check-input[type=radio] ~ label::before {
  border-radius: 50%;
}

.dropdown-item {
  outline: none;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--white-bg);
}

.breadcrumb {
  float: right;
  margin-bottom: 0;
  background-color: unset;
  padding-top: 5px;
}

header ::-webkit-input-placeholder {
  /* Edge */
  color: #c0d8f0 !important;
  font-weight: 400;
}
header :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c0d8f0 !important;
  font-weight: 400;
}
header ::placeholder {
  color: #c0d8f0 !important;
  font-weight: 400;
}

.report-bottom-paginate {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}
.report-bottom-paginate .item-perpage-select {
  display: flex;
}
.report-bottom-paginate .item-perpage-select label {
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  line-height: 28px;
  padding-right: 10px;
  height: 32px;
}
.report-bottom-paginate .item-perpage-select select {
  width: auto;
  padding: 0 15px 0 10px;
  line-height: 32px;
  height: 32px;
  border-radius: 3px;
  background-position: right;
}

.report-page .filterDiv .form-select, .report-page .filterDiv .form-select:focus, .report-page .filterDiv .form-control,
.report-page .filterDiv .form-control:focus, .report-page .filterDiv .form-attribute, .report-page .filterDiv .form-attribute:focus, .report-page .filterDiv .ng-select .ng-select-container {
  border: 1px solid #508fc8;
  border-radius: 4px;
  background-color: transparent;
  color: #fff;
}
.report-page .filterDiv .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #c0d8f0 !important;
  font-size: 12px;
}
.report-page .filterDiv ::-webkit-input-placeholder {
  /* Edge */
  color: #c0d8f0 !important;
  font-weight: 400;
}
.report-page .filterDiv :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c0d8f0 !important;
  font-weight: 400;
}
.report-page .filterDiv ::placeholder {
  color: #c0d8f0 !important;
  font-weight: 400;
}
.report-page .filterDiv .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #c0d8f0 transparent transparent;
}
.report-page .filterDiv .ng-select .ng-clear-wrapper {
  color: #c0d8f0;
}
.report-page .filterDiv .form-select {
  background-image: url("assets/images/icon/arrow-down.png");
  position: relative;
  background-size: 10px;
}
.report-page .filterDiv .form-check label:before {
  border: 2px solid #81c2f0;
}
.report-page .filterDiv .form-check input:checked + label:before {
  background-color: #81c2f0;
}
.report-page .filterDiv .form-check label:after {
  border: 2px solid #2370b0;
  border-width: 0 2px 2px 0;
}
.report-page .filterDiv .form-check label {
  color: #fff;
}

.report-table table tbody tr td .td-btn, .report-table table tbody tr td .td-btn:hover {
  color: #fff;
}

.report-page {
  padding: 0;
}

.breadcrumb .back-btn {
  color: #fff !important;
  background-color: #508fc8;
  border-radius: 3px;
  border: none;
}
.breadcrumb .back-btn:hover {
  background-color: #195991;
}

.no-radius {
  border-radius: 3px !important;
}

main {
  padding-top: 89px;
}
main .page-title {
  background-color: var(--blue-bg);
  padding: 0 10px;
}
main .page-title h3 {
  font-size: 16px;
  line-height: 36px;
  font-weight: 500;
  color: #fff;
  vertical-align: middle;
}
main .filterDiv {
  background-color: var(--blue-bg);
  margin-top: 0;
  border-radius: 0;
  padding: 0 10px 10px;
}

.report-table .breadcrumb {
  float: unset;
  margin-bottom: 10px;
}

.ng-select.ng-select-opened .ng-select-container,
.ng-dropdown-panel {
  z-index: 1 !important;
}

.breadcrumb .back-btn {
  line-height: 26px;
  height: 26px;
  text-transform: uppercase;
  color: var(--white-text) !important;
}

.breadcrumb .back-btn i {
  margin-right: 5px;
}

.breadcrumb ul li {
  display: inline-block;
  padding: 0 10px 0 0;
  font-weight: 600;
  position: relative;
}

.breadcrumb ul li a {
  color: var(--blue-text) !important;
}

.breadcrumb ul li:last-child a {
  pointer-events: none;
  color: inherit !important;
  font-weight: normal;
}

.breadcrumb ul li:after {
  content: "\f105";
  font-family: "FontAwesome";
  margin-left: 10px;
}

.breadcrumb ul li:last-child::after {
  display: none;
}

.suspend {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
}

.suspend-status {
  flex-basis: 420px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #b30000;
  border: 1px solid rgba(179, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  font-weight: 600;
  border-radius: 4px;
}

.report-table thead tr th:first-child {
  border-left-color: #f5f8fe;
}

.report-table thead tr th:last-child {
  border-right-color: #f5f8fe;
}

.light-back {
  background-color: var(--back-light);
}

.light-lay {
  background-color: var(--lay-light);
}

.btn, .btn:focus, .btn:active, .btn:focus:active {
  transition: opacity 0.25s ease-in-out, background-color 0.25s ease-in-out, width 0.25s ease-in-out;
  font-size: 12px;
  height: 34px;
  opacity: 1;
  line-height: 32px;
  outline: 0;
  color: var(--white-text);
  text-align: center;
  user-select: none;
  overflow: hidden;
  display: inline-block;
  position: relative;
  font-weight: 600;
  vertical-align: bottom;
  padding: 0 10px;
  border-radius: 2px;
}

.btn-primary {
  color: #fff;
  background-color: #5b73e8;
  border-color: #5b73e8;
}

.btn-primary:hover {
  color: #fff;
  background-color: #4d62c5;
  border-color: #495cba;
}

.btn-light {
  color: #000;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-light:hover {
  color: #000;
  background-color: #efefef;
  border-color: #efefef;
}

.btn-success {
  color: #fff;
  background-color: #34c38f;
  border-color: #34c38f;
}

.btn-soft-success {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.25);
  border-color: rgba(52, 195, 143, 0.25);
}

.btn-success:hover {
  color: #fff;
  background-color: #2ca67a;
  border-color: #2a9c72;
}

.btn-info {
  color: #fff;
  background-color: #50a5f1;
  border-color: #50a5f1;
}

.btn-info:hover {
  color: #fff;
  background-color: #448ccd;
  border-color: #4084c1;
}

.btn-warning {
  color: #fff;
  background-color: #f1b44c;
  border-color: #f1b44c;
}

.btn-warning:hover {
  color: #fff;
  background-color: #cd9941;
  border-color: #cd9941;
}

.btn-danger {
  color: #fff;
  background-color: #f46a6a;
  border-color: #f46a6a;
}

.btn-soft-danger {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.25);
  border-color: rgba(244, 106, 106, 0.25);
}

.btn-danger:hover {
  color: #fff;
  background-color: #cf5a5a;
  border-color: #c35555;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #2c3136;
  border-color: #2a2e33;
}

.btn-secondary {
  color: #fff;
  background-color: #74788d;
  border-color: #74788d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #636678;
  border-color: #5d6071;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

a.btn.btn-link {
  color: inherit;
  height: 16px;
  line-height: 16px;
}

main {
  min-width: auto;
  width: 100%;
  position: relative;
  margin: 0 auto;
  max-width: calc(100% - 0px);
}

.main-div .left-section {
  padding-left: 235px;
  width: 100%;
  height: 100%;
  padding-right: 340px;
}

.left-section .header-bg-img {
  margin-bottom: 10px;
}

.left-section .header-bg-img img {
  width: 100%;
  object-fit: cover;
  height: 220px;
  object-position: bottom right;
}

.main-div .left-section .main-row {
  padding: 15px 0 0;
  border-radius: 2px;
}

.full-wrap {
  margin: 0;
  padding: 0;
}

.td-btn.btn-danger.btn-soft-danger, .td-btn.btn-success.btn-soft-success {
  border-radius: 15px;
  padding: 0 10px;
}

.td-btn {
  display: inline-block;
  margin: 0 4px;
  height: 24px;
  line-height: 25px;
  text-align: center;
  border-radius: 2px;
  min-width: 24px;
  padding: 0 3px;
  font-weight: 600;
  border: none;
  font-size: 12px;
}

a {
  cursor: pointer;
}

.td-btn i {
  font-size: 14px;
}

.td-edit-btn.btn-info {
  border-radius: 50%;
  width: 22px;
  display: inline-block;
  text-align: center;
  line-height: 22px;
  height: 22px;
  font-size: 12px;
  font-weight: bold;
}

span.userLabel {
  background-color: #febecc;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
}
span.userLabel span {
  color: #000;
}

.dashboard-page .right-section .live-game .game-body {
  overflow-y: scroll;
  height: calc(100vh - 100px);
}

.dashboard-page .right-section .live-game {
  overflow: unset;
  height: auto;
}

.scroller {
  scrollbar-color: #dcdcdc #f0f8ff;
  scrollbar-width: thin;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.back-rate {
  background-color: var(--back-rate);
}

.lay-rate {
  background-color: var(--lay-rate);
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--error);
}

.parent-modal .body-inner ul {
  padding-left: 20px;
}

.page-title {
  margin-bottom: 0;
}

.page-title h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  display: inline-block;
}

.page-sub-title h3 {
  font-size: 15px;
  font-weight: 600;
}

.filterDiv {
  margin-top: 5px;
  background-color: var(--white-bg);
  padding: 10px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
}

.report-table {
  background-color: var(--white-bg);
  padding: 0 10px 15px;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  overflow-x: auto;
}

.hierarchy {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 10px;
  margin-bottom: -5px;
}
.hierarchy ul {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  margin: 5px 0 0;
  width: -webkit-min-content;
  width: min-content;
}
.hierarchy ul li {
  width: auto;
  height: 26px;
  background: #60a0d6;
  position: relative;
  margin-left: 16px;
  color: var(--blue-text);
  padding-left: 10px;
  line-height: 26px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
}
.hierarchy ul li:before {
  content: "";
  position: absolute;
  top: 0;
  left: -13px;
  width: 0;
  height: 0;
  border-top: 13px solid #60a0d6;
  border-bottom: 13px solid #60a0d6;
  border-left: 13px solid transparent;
}
.hierarchy ul li:after {
  content: "";
  position: absolute;
  right: -13px;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid #60a0d6;
}

.report-table table thead tr th, .report-table table tbody tr td {
  border: none;
  border-width: 0 0 1px 1px;
  white-space: nowrap;
  color: #83a2da;
  font-weight: 400;
  padding: 5px 10px;
  font-size: 12px;
  vertical-align: middle;
  white-space: nowrap;
}

.report-table table tbody tr:nth-child(even) td {
  background-color: #f5f8fe;
}

.report-table table tbody tr:hover td {
  background-color: #e8f2fa;
}

.report-table table, .report-table .dataTable.no-footer {
  border-bottom: none;
}

.report-table table thead tr th {
  border-bottom: 2px solid #5f9fd5 !important;
  border-top: 2px solid #f5f8fe;
  padding: 5px 10px;
  line-height: 30px !important;
  color: #6c767f;
  font-weight: 500;
  font-size: 13px;
}

.report-table table thead tr th:last-child, .report-table table tbody tr td:last-child {
  border-right-width: 1px;
}

.report-table table thead tr {
  background-color: #f5f8fe;
}

.report-table .dataTables_wrapper .dataTables_length, .report-table .dataTables_wrapper .dataTables_filter {
  margin-bottom: 15px;
}

.report-table .dataTables_wrapper .dataTables_filter input, .report-table .dataTables_wrapper .dataTables_length select {
  box-shadow: none;
  outline-offset: 0;
  outline: none;
  padding: 0 10px;
  height: 30px;
}

.paginate_button.first, .paginate_button.last {
  display: none !important;
}

.paginate_button {
  border: 1px solid #ccc !important;
  padding: 0 10px !important;
  line-height: 30px;
  border-radius: 2px !important;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button {
  background-color: #ccc !important;
  font-weight: bold;
  border-radius: 2px !important;
}

.paging_full_numbers {
  padding-top: 10px !important;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current,
.dataTables_wrapper .dataTables_paginate span .paginate_button.current:hover {
  background-color: #46b8db !important;
  border: 1px solid #46b8db !important;
  color: #fff !important;
  background: unset;
}

.dateTimePicker popover-container {
  max-width: fit-content;
}
.dateTimePicker popover-container .popover-body {
  padding: 0;
}

.datetimepicker {
  padding: 0 !important;
  box-shadow: 2px 2px 4px #ccc;
  border: none !important;
  border-radius: 2px !important;
}
.datetimepicker:before {
  border-bottom: 7px solid #013171 !important;
  border-bottom-color: #013171 !important;
}
.datetimepicker:after {
  border-bottom: 6px solid #013171 !important;
}
.datetimepicker .datetimepicker-days table thead, .datetimepicker .datetimepicker-months table thead, .datetimepicker .datetimepicker-years table thead {
  background-color: var(--blue-text);
}
.datetimepicker .datetimepicker-days table thead tr th, .datetimepicker .datetimepicker-months table thead tr th, .datetimepicker .datetimepicker-years table thead tr th {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding: 15px 10px;
  border-radius: 0;
}
.datetimepicker .datetimepicker-days table thead tr th.prev span:before, .datetimepicker .datetimepicker-months table thead tr th.prev span:before, .datetimepicker .datetimepicker-years table thead tr th.prev span:before {
  text-align: center;
  content: "\f053";
  font-family: "FontAwesome";
  width: 16px;
  height: 16px;
  color: #fff;
  display: block;
  margin: 0;
}
.datetimepicker .datetimepicker-days table thead tr th.next span:after, .datetimepicker .datetimepicker-months table thead tr th.next span:after, .datetimepicker .datetimepicker-years table thead tr th.next span:after {
  text-align: center;
  content: "\f054";
  font-family: "FontAwesome";
  width: 16px;
  height: 16px;
  color: #fff;
  display: block;
  margin: 0;
}
.datetimepicker .datetimepicker-days table thead tr th:hover, .datetimepicker .datetimepicker-months table thead tr th:hover, .datetimepicker .datetimepicker-years table thead tr th:hover {
  background-color: transparent !important;
}
.datetimepicker .datetimepicker-days table thead tr:nth-child(2), .datetimepicker .datetimepicker-months table thead tr:nth-child(2), .datetimepicker .datetimepicker-years table thead tr:nth-child(2) {
  background-color: var(--light-blue-bg);
}
.datetimepicker .datetimepicker-days table thead tr:nth-child(2) th, .datetimepicker .datetimepicker-months table thead tr:nth-child(2) th, .datetimepicker .datetimepicker-years table thead tr:nth-child(2) th {
  color: var(--blue-text);
  padding: 5px 10px;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
}
.datetimepicker .datetimepicker-days table tbody tr td, .datetimepicker .datetimepicker-months table tbody tr td, .datetimepicker .datetimepicker-years table tbody tr td {
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 50%;
  width: 38px;
  height: 38px !important;
}
.datetimepicker .datetimepicker-days table tbody tr td:hover, .datetimepicker .datetimepicker-days table tbody tr td.active, .datetimepicker .datetimepicker-months table tbody tr td:hover, .datetimepicker .datetimepicker-months table tbody tr td.active, .datetimepicker .datetimepicker-years table tbody tr td:hover, .datetimepicker .datetimepicker-years table tbody tr td.active {
  background-color: var(--light-blue-bg) !important;
  background-image: unset !important;
}
.datetimepicker .datetimepicker-days table tbody tr td.active, .datetimepicker .datetimepicker-months table tbody tr td.active, .datetimepicker .datetimepicker-years table tbody tr td.active {
  background-color: var(--blue-bg) !important;
  background-image: unset;
}
.datetimepicker .datetimepicker-minutes table thead, .datetimepicker .datetimepicker-hours table thead {
  background-color: var(--blue-text);
}
.datetimepicker .datetimepicker-minutes table thead tr th, .datetimepicker .datetimepicker-hours table thead tr th {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding: 15px 10px;
  border-radius: 0;
}
.datetimepicker .datetimepicker-minutes table thead tr th span, .datetimepicker .datetimepicker-hours table thead tr th span {
  line-height: 20px;
}
.datetimepicker .datetimepicker-minutes table thead tr th.prev span:before, .datetimepicker .datetimepicker-hours table thead tr th.prev span:before {
  text-align: center;
  content: "\f053";
  font-family: "FontAwesome";
  width: 16px;
  height: 16px;
  color: #fff;
  display: block;
  margin: 0;
}
.datetimepicker .datetimepicker-minutes table thead tr th.next span:after, .datetimepicker .datetimepicker-hours table thead tr th.next span:after {
  text-align: center;
  content: "\f054";
  font-family: "FontAwesome";
  width: 16px;
  height: 16px;
  color: #fff;
  display: block;
  margin: 0;
}
.datetimepicker .datetimepicker-minutes table thead tr th:hover, .datetimepicker .datetimepicker-hours table thead tr th:hover {
  background-color: transparent !important;
}
.datetimepicker .datetimepicker-minutes table thead tr:nth-child(2), .datetimepicker .datetimepicker-hours table thead tr:nth-child(2) {
  background-color: var(--light-blue-bg);
}
.datetimepicker .datetimepicker-minutes table thead tr:nth-child(2) th, .datetimepicker .datetimepicker-hours table thead tr:nth-child(2) th {
  color: var(--blue-text);
  padding: 5px 12px;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
}
.datetimepicker .datetimepicker-minutes table tbody tr td, .datetimepicker .datetimepicker-hours table tbody tr td {
  border: none;
  border-radius: 2px;
  padding: 0;
}
.datetimepicker .datetimepicker-minutes table tbody tr td span, .datetimepicker .datetimepicker-hours table tbody tr td span {
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 2px;
  height: 34px;
  line-height: 34px;
}
.datetimepicker .datetimepicker-minutes table tbody tr td span:hover, .datetimepicker .datetimepicker-minutes table tbody tr td span.active, .datetimepicker .datetimepicker-hours table tbody tr td span:hover, .datetimepicker .datetimepicker-hours table tbody tr td span.active {
  background-color: var(--light-blue-bg) !important;
  background-image: unset !important;
}
.datetimepicker .datetimepicker-minutes table tbody tr td span.active, .datetimepicker .datetimepicker-hours table tbody tr td span.active {
  background-color: var(--blue-bg) !important;
  background-image: unset;
}

.modal-dialog {
  max-width: 680px;
}
.modal-dialog .modal-content {
  border: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.modal-dialog .modal-content .modal-header {
  background-color: var(--blue-bg);
  padding: 0 15px;
  line-height: 50px;
  height: 50px;
}
.modal-dialog .modal-content .modal-header .modal-title {
  color: #8fc8f6;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  font-family: "Oswald";
}
.modal-dialog .modal-content .modal-header .close {
  opacity: 1;
}
.modal-dialog .modal-content .modal-header .close i {
  color: #8fc8f6;
  font-size: 18px;
  font-weight: normal;
  background-color: var(--blue-bg);
  border-radius: 50%;
  padding: 5px;
}
.modal-dialog .modal-content .modal-header .close i:before {
  content: "";
  background-image: url("/assets/images/icon/close.png");
  background-size: 16px 16px;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  display: block;
  background-position: center center;
}
.modal-dialog .modal-content .modal-header .close:hover i {
  background-color: var(--header-bg);
}
.modal-dialog .modal-content .btn-danger {
  margin-left: 10px;
}
.modal-dialog .modal-content .modal-body {
  padding: 10px 10px;
  overflow-y: auto;
}
.modal-dialog .modal-content .modal-body .body-inner .form-group {
  margin-bottom: 15px;
}
.modal-dialog .modal-content .modal-body .body-inner .table {
  margin-bottom: 0;
}
.modal-dialog .modal-content .modal-body .body-inner .table thead tr th {
  border: none !important;
  background-color: #f5f8fe !important;
  border-bottom: 2px solid #5f9fd5 !important;
  border-top: 2px solid #f5f8fe;
  padding: 5px;
  line-height: 30px !important;
  color: #6c767f;
  font-weight: 500;
  font-size: 13px;
}
.modal-dialog .modal-content .modal-body .body-inner .table thead tr:nth-child(2) th {
  background-color: #fff !important;
}
.modal-dialog .modal-content .modal-body .body-inner .table tbody tr th {
  border: none !important;
  background-color: #f5f8fe !important;
  border-bottom: 2px solid #5f9fd5 !important;
  border-top: 2px solid #f5f8fe;
  padding: 5px;
  line-height: 30px !important;
  color: #6c767f;
  font-weight: 500;
  font-size: 13px;
}
.modal-dialog .modal-content .modal-body .body-inner .table tbody tr td {
  border: none;
  white-space: nowrap;
  padding: 5px;
  font-size: 13px;
  color: #83a2da;
}
.modal-dialog .modal-content .modal-body .body-inner .table tbody tr:nth-child(odd) {
  background-color: #f5f8fe;
}
.modal-dialog .modal-content .modal-body.no-scroll-body {
  height: auto;
}
.modal-dialog .modal-content .modal-footer {
  border-top: 2px solid #dee2e6;
  padding: 5px 10px;
}

.modal-lg.sportSetting-modal {
  max-width: 900px;
}

.sharing-modal .partnershipList {
  margin-bottom: 20px;
}
.sharing-modal .partnershipList .heading {
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
}
.sharing-modal .partnershipList .form-group input, .sharing-modal .partnershipList .form-group input:focus {
  background-color: #EEF1F5;
  border: none;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  padding: 8px 15px;
  min-height: 34px;
}
.sharing-modal .partnershipList .form-group label {
  margin-bottom: 5px;
}

.right-section .live-game .game-header {
  padding: 0 10px;
  background-color: var(--main-heading-bg);
  border-radius: 4px 4px 0 0;
  line-height: 32px;
  color: var(--white-text);
  height: 32px;
  font-size: 13px;
  font-weight: 600;
}

.right-section .live-game .game-header img {
  margin-right: 3px;
  width: 16px;
}

.right-section .live-game .game-body {
  padding: 0 10px 10px;
  margin-bottom: 10px;
  border-radius: 0 0 4px 4px;
  background-color: var(--white-bg);
}

.right-section .live-game .game-body .list-title h3 {
  font-size: 13px;
  font-weight: 600;
  position: relative;
  color: var(--black-text);
  margin-bottom: 15px;
  line-height: 30px;
}

.right-section .live-game .game-body .list-title h3:after {
  content: "";
  position: absolute;
  width: 35%;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid var(--blue-bg);
}

@media (max-width: 992px) {
  .main-div .left-section {
    padding-left: 10px;
  }

  .report-table {
    padding: 10px 0;
  }

  .page-title {
    padding: 0 10px;
  }
}
.pink {
  background-color: var(--lay-rate) !important;
}

.blue {
  background-color: var(--back-rate) !important;
}

.green, .green td {
  color: #35c38f !important;
}

.red, .red td {
  color: #f46a6a !important;
}

.report-page .btn-danger {
  margin-left: 10px;
}

@media (min-width: 993px) {
  .main-div .right-section {
    width: 315px;
    position: fixed;
    height: auto;
    top: 100px;
    bottom: 0;
    right: 10px;
    z-index: 1;
    border-radius: 4px;
  }
}
@media (max-width: 992px) {
  .main-div .right-section {
    width: 100%;
    padding: 0 10px;
  }
}
.bets-modal.modal-dialog {
  max-width: 1080px;
}
.bets-modal.modal-dialog .modal-content .modal-body .body-inner .betFilter {
  margin-bottom: 8px;
}
.bets-modal.modal-dialog .modal-content .modal-body .body-inner form table tbody tr td {
  background-color: transparent;
}

.deleteBet-modal.modal-dialog {
  max-width: 320px;
}
.deleteBet-modal.modal-dialog .modal-body {
  height: auto;
  padding-top: 25px;
  padding-bottom: 25px;
}
.deleteBet-modal.modal-dialog .body-inner .title {
  display: flex;
  align-items: center;
}
.deleteBet-modal.modal-dialog .body-inner .title .left i {
  color: #f46a6a;
  margin-right: 15px;
  font-size: 36px;
}
.deleteBet-modal.modal-dialog .body-inner .title .right h3 {
  text-transform: uppercase;
  font-size: 22px;
  line-height: 20px;
}
.deleteBet-modal.modal-dialog .body-inner .title .right p {
  margin: 0;
  color: #939191;
  font-weight: 500;
}
.deleteBet-modal.modal-dialog .modal-footer .btn {
  margin-left: 10px;
}

.mat-menu-content .form-check {
  display: block;
  margin: 0;
  padding: 0 15px;
  line-height: 32px;
  min-width: 140px;
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

.report-page .form-group, .tab-content .form-group, .loginPage .form-group {
  position: relative;
}
.report-page .form-group .form-label, .tab-content .form-group .form-label, .loginPage .form-group .form-label {
  position: absolute;
  top: -10px;
  left: 5px;
  background-color: #fff;
  z-index: 2;
  padding: 0 5px;
  color: #777;
  font-weight: 400;
  margin: 0;
}
.report-page .form-group .form-label ~ input, .report-page .form-group .form-label ~ .ng-select, .report-page .form-group .form-label ~ .form-select, .tab-content .form-group .form-label ~ input, .tab-content .form-group .form-label ~ .ng-select, .tab-content .form-group .form-label ~ .form-select, .loginPage .form-group .form-label ~ input, .loginPage .form-group .form-label ~ .ng-select, .loginPage .form-group .form-label ~ .form-select {
  line-height: 34px;
  margin-top: 20px;
}
.report-page .form-group .form-label ~ input .ng-select-container, .report-page .form-group .form-label ~ .ng-select .ng-select-container, .report-page .form-group .form-label ~ .form-select .ng-select-container, .tab-content .form-group .form-label ~ input .ng-select-container, .tab-content .form-group .form-label ~ .ng-select .ng-select-container, .tab-content .form-group .form-label ~ .form-select .ng-select-container, .loginPage .form-group .form-label ~ input .ng-select-container, .loginPage .form-group .form-label ~ .ng-select .ng-select-container, .loginPage .form-group .form-label ~ .form-select .ng-select-container {
  line-height: 34px;
  height: 34px;
}
.report-page .form-group .form-label ~ .input-group, .report-page .form-group .form-label ~ .input-icons, .tab-content .form-group .form-label ~ .input-group, .tab-content .form-group .form-label ~ .input-icons, .loginPage .form-group .form-label ~ .input-group, .loginPage .form-group .form-label ~ .input-icons {
  margin-top: 20px;
}
.report-page .form-group .form-label ~ .input-group input, .report-page .form-group .form-label ~ .input-icons input, .tab-content .form-group .form-label ~ .input-group input, .tab-content .form-group .form-label ~ .input-icons input, .loginPage .form-group .form-label ~ .input-group input, .loginPage .form-group .form-label ~ .input-icons input {
  line-height: 34px;
}

.sport-setting-body .nav-tabs {
  border-bottom: none;
  background-color: #efefef;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  margin-bottom: 15px;
}
.sport-setting-body .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid var(--blue-bg) !important;
  background-color: transparent !important;
}
.sport-setting-body .nav-tabs .nav-link:hover, .sport-setting-body .nav-tabs .nav-link:focus {
  border: none !important;
  border-bottom: 2px solid var(--blue-bg) !important;
}
.sport-setting-body .detail-row-heading h4 {
  margin-bottom: 0;
  color: var(--blue-text);
  font-weight: 500;
  font-size: 15px;
  position: relative;
  letter-spacing: 0.5px;
  display: inline-block;
}
.sport-setting-body .detail-row-heading h4:after {
  content: "";
  height: 2px;
  width: 70%;
  background-color: var(--blue-bg);
  position: absolute;
  bottom: -6px;
  left: 0;
  display: none;
}
.sport-setting-body .col-md-3.col-sm-4.col-12 {
  padding-right: 15px;
}
.sport-setting-body .col-md-3.col-sm-4.col-12:nth-child(4n-0) {
  padding-right: 0;
}
.sport-setting-body .form-group span {
  font-size: 11px;
  font-weight: 400;
}

@media (min-width: 993px) {
  .hide-on-large {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .show-on-mobile {
    display: none;
  }
}
@media (max-width: 1024px) {
  .mobile-nav-active.mobile-nav {
    left: 0;
  }

  .mobile-nav {
    position: fixed;
    top: 51px;
    bottom: 0;
    z-index: 10002;
    overflow-y: auto;
    left: -260px;
    width: 260px;
    padding-top: 0;
    background: #fff;
    transition: 0.4s;
    display: unset;
    padding: 0;
  }
  .mobile-nav ul li {
    line-height: 38px;
    padding: 0;
    border-top: 1px solid #efefef;
    height: auto;
    position: relative;
  }
  .mobile-nav ul li a {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
    text-align: left;
    position: relative;
    text-transform: capitalize;
    display: block;
    cursor: pointer;
  }
  .mobile-nav ul li a i {
    margin-right: 10px;
  }
  .mobile-nav ul li a.dropdown-toggle::after {
    right: 25px;
    position: absolute;
    content: "\f107";
    font-family: "FontAwesome";
    border: none;
    font-size: 20px;
  }
  .mobile-nav ul li .dropdown-menu {
    position: relative;
    float: unset;
    padding: 0;
    border: none;
    margin: 0;
    border-radius: 0;
    background-color: #eee;
  }

  .mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 10000;
    top: 50px;
    left: 0;
    position: fixed;
    background: rgba(204, 204, 204, 0.5);
    overflow: hidden;
    display: none;
  }

  .mobile-nav-overly.mobile-nav-block {
    display: block;
  }

  .header-top-menu {
    display: none;
  }

  header .header-menu nav form {
    display: none;
  }

  .navbar-collapse.menu-list {
    display: flex;
    justify-content: space-between;
  }

  .mobile-nav-toggle {
    border: 0;
    padding: 0;
    background: none;
    font-size: 22px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    background: transparent;
    width: 40px;
    height: 30px;
    cursor: pointer;
    color: var(--white-text);
  }

  header .header-menu .navbar-brand {
    padding-left: 0 !important;
    width: 160px !important;
  }

  .main-div .left-section {
    padding-top: 0;
  }

  main {
    padding-top: 52px;
  }

  .hide-on-med-and-down {
    display: none !important;
  }

  main .filterDiv, main .page-title {
    background-color: var(--header-bg);
  }

  .filterDiv .col-md-1, .page-title .col-md-1 {
    width: auto !important;
  }

  .hierarchy ul li {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .mobile-hide-list > li {
    display: none;
  }

  .mobile-hide-list li.dropdown {
    display: block;
  }

  .hide-on-down {
    display: none !important;
  }

  .modal-dialog .modal-content .modal-header .modal-title {
    white-space: nowrap;
    width: calc(100% - 45px);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .hide-on-mobile {
    display: none;
  }

  .show-on-mobile {
    display: block;
  }

  .report-bottom-paginate {
    flex-direction: column;
  }

  .report-bottom-paginate .user-list-pagination {
    text-align: right;
    padding-top: 5px;
  }

  .report-bottom-paginate .item-perpage-select {
    padding-left: 10px;
  }
}
.pr-15 {
  padding-right: 15px;
}

.user-list-pagination {
  float: right;
}

.back-color {
  background-color: var(--back-rate) !important;
}

.lay-color {
  background-color: var(--lay-rate) !important;
}

.report-table td {
  padding: 0 15px;
}
.report-table td .sport-name {
  font-weight: 500;
  color: #00C14D;
  font-size: 12px;
}
.report-table td .market-name {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}
.report-table td .series-name {
  font-weight: 500;
  color: #363636;
}
.report-table td .match-name {
  text-decoration: underline;
  color: #0094E7;
  font-size: 15px;
  font-weight: 600;
}
.report-table td .selection {
  font-size: 14px;
  font-weight: 600;
}
.report-table td .dec-cell {
  max-width: 70%;
  display: block;
  color: var(--blue-text);
  font-weight: 400;
  line-height: 23px;
}
.report-table td .date {
  font-size: 12px;
  color: #363636;
}
.report-table .back-btn {
  background-color: #0094e7;
  color: var(--white-text);
  border-radius: 8px;
  padding: 0 10px;
  min-width: 50px;
  width: auto !important;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
}
.report-table .lay-btn {
  background-color: #faa9ba;
  color: var(--white-text);
  border-radius: 8px;
  padding: 0 10px;
  min-width: 50px;
  width: auto !important;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
}

.bs-timepicker-field {
  color: #000 !important;
}

.report-table table tbody:nth-child(3) tr td {
  font-size: 15px;
  font-weight: 600;
  color: inherit;
}

.ng-dropdown-panel.ng-select-bottom {
  width: 100%;
  border: 1px solid rgba(192, 216, 236, 0.4);
  border-radius: 4px 4px;
  overflow: hidden;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items {
  max-height: 70vh;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option {
  white-space: unset;
  overflow: hidden;
  text-overflow: unset;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid rgba(192, 216, 236, 0.4);
  background-color: #f5f8fe;
  color: #2370b0;
}

.tooltip {
  z-index: 3;
}